.header-main{
    display: flex;
    background-color: white;
    border-bottom: solid 1px black;
    height: 70px;
    width: 100%;
    margin: 0;
}

.header-nav{
    display: flex;
    justify-content: center;
    align-items: center;
}

.header-nav-link{
    display: flex;
}

.nav-link{
    color: black;
    width: max-content;
}

.header-links{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 3%;
    right: 0%;
}

.bird-icon{
    display: flex;
    justify-content: center;
    align-items: center;
}

li{
    list-style: none;
}

#header-logo{
    border-radius: 100%;
}

.link-list{
    display: flex;
    justify-content: center;
    align-items: center;
}

.link-items{
    margin: -20px;
}

.Icon{
    width: 40%;
}

.drop-link{
    display: none;
}

@media only screen and (max-width: 1050px) {

    .header-nav-link{
        display: none;
    }

  }

@media only screen and (max-width: 700px) {

    .header-links{
        top: 6%;
        right: -5%;
    }
    
    .link-list{
        display: none;
    }

    .drop-link{
        display: inline;
    }
    
  }