.main-description{
    display: flex;
    flex-direction: column;
    position: relative;
    height: auto;
}

.section-1, .section-2, .section-3, .section-4, .section-5{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
    font-weight: bold;
    color: white;
}

.section-1, .section-3, .section-5{
    flex-direction: row;
    background-color: rgb(35, 35, 39);
}

.section-2, .section-4{
    flex-direction: row-reverse;
    background-color: rgb(25, 25, 25);
}

.text-zone{
    width: auto;
    padding: 30px;
    font-size: x-large;
    text-align: center;
    vertical-align: middle;
    font-family: sans-serif;
    font-weight: 200;
}

.game-image{
    width: 50%;
}

@media only screen and (max-width: 700px) {

    .section-1, .section-2, .section-3, .section-4, .section-5{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 30px;
        font-weight: bold;
        color: white;
    }

    .game-image{
        width: 100%;
    }

}