.footer-main{
    display: flex;
    border-top: solid 1px black;
    background: white;
    height: auto;
}

a{
    color: black;
}

.footer-link{
    margin: 10px;
}

@media only screen and (max-width: 700px) {

    .footer-main{
        display: flex;
        justify-content: center;
        align-items: center;
        border-top: solid 1px black;
        background: white;
        height: auto;
    }

}
