.main-main{
    display: flex;
    flex-direction: column;
    background-color: black;
    margin: 0%;
    padding: 0%;
}

.top-section{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    height: fit-content;
    margin: 0%;
    padding: 0%;
}

#img-promo{
    width: 100%;
    margin: 0%;
    padding: 0%;
    pointer-events: none;
}

.top-button{
    position: absolute;
    text-align: center;
    top: 60%;
}

.main-achat{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 300px;
    height: fit-content;
}

#button-achat{
    width: 200px;
    height: auto;
    border-radius: 0%;
    transition: 0.3s;
    text-transform: uppercase;
    font-weight: bold;
    font-size: x-large;
    color: white;
    box-shadow: 2px 2px rgb(0, 0, 0, 0.3);
}

#button-achat:hover{
    width: 200px;
    height: auto;
    border-radius: 0%;
    transition: 0.3s;
    text-transform: uppercase;
    font-weight: bold;
    font-size: x-large;
    color: rgb(163, 163, 163);
}

.release-date{
    color: white;
    text-shadow: 2px 2px rgb(0, 0, 0, 0.3);
    text-transform: uppercase;
    text-align: center;
    font-size: x-large;
    width: 100%;
    margin: 0;
}

@media only screen and (max-width: 1200px) {
    .main-achat{
        height: fit-content;
    }

    .top-button{
        position: absolute;
        text-align: center;
        top: 60%;
    }
}

@media only screen and (max-width: 700px) {

    .top-button{
        position: absolute;
        text-align: center;
        top: 55%;
    }

    #button-achat{
        width: 160px;
        height: auto;
        border-radius: 0%;
        text-transform: uppercase;
        font-weight: bold;
        font-size: medium;
    }

    #button-achat:hover{
        width: 160px;
        height: auto;
        border-radius: 0%;
        text-transform: uppercase;
        font-weight: bold;
        font-size: medium;
        color: rgb(163, 163, 163);
    }
  }