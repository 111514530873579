.news-main{
    display: flex;
    flex-direction: column;
    align-items: center;
    background: url(../Background/UpdateBackground.webp) no-repeat fixed;
    background-size: cover;
}

.news-updates{
    display: flex;
    flex-direction: column-reverse;
    width: 700px;
}

.update-block{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2%;
    width: auto;
    height: 400px;
    transition: 0.3s;
}

#backImg{
    box-shadow: 2px 2px rgb(0, 0, 0, 0.3);
}

.update-desc{
    position: relative;
    top: 70%;
    left: -30%;
    background-image: var(--bs-gradient);
    background-color: rgb(56, 56, 96);
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    box-shadow: 2px 2px rgb(0, 0, 0, 0.3);
    transition: 0.3s;
}

.update-desc:hover{
    transition: 0.3s;
    color: rgb(169, 169, 169);
}

@media only screen and (max-width: 1000px) {

    .news-updates{
        width: 600px;
    }

    .update-block{
        height: 350px;
    }
}

@media only screen and (max-width: 700px) {

    .news-main{
        height: 1000px;
    }

    .news-updates{
        width: 500px;
    }

    .update-block{
        height: 300px;
    }

}

@media only screen and (max-width: 600px) {

    .news-updates{
        width: 300px;
    }

    .update-block{
        height: 225px;
    }

    .update-desc{
        top: 60%;
        left: 0%;
    }

}

@media only screen and (max-width: 350px) {

    .news-updates{
        width: auto;
    }

    .update-block{
        height: auto;
        margin-bottom: 40%;
    }

}