.main-privacy{
    display: flex;
    flex-direction: column;
    justify-content: baseline;
    margin-left: 100px;
}

.main-privacy-policy{
    display: flex;
    flex-direction: column;
    justify-content: baseline;
    border-top: solid 1px rgb(223, 223, 223);
}

.main-interpretation{
    display: flex;
    flex-direction: column;
    justify-content: baseline;
}

.list{
    display: flex;
    flex-direction: column;
    list-style: circle;
}

.list{
    list-style-type: circle;
}


.main-footer{
    border-top: solid 1px rgb(223, 223, 223);
    margin-left: -100px;
}

.footer-text{
    margin: 30px;
}

@media only screen and (max-width: 1200px) {
    .main-privacy{
        display: flex;
        flex-direction: column;
        justify-content: baseline;
        margin-left: 0;
    }

    .main-footer{
        border-top: solid 1px rgb(223, 223, 223);
        margin-left: 0;
    }
  }